<template>
     <CategoryPost/>
</template>

<script>


import CategoryPost from '@/components/post/CategoryPost.vue'

export default {
  name: 'CategoryPostView',
  components: {
    CategoryPost
  },
  data(){
    return {
      category: null,
      actionCreate: false
    }
  },
  created(){
    const categoryId = this.$route.params.id;

    console.log("categoryId", categoryId)

    if(categoryId){
        this.getCategory(this.categoryId)
        this.actionCreate = true
    }
  },
  methods: {
    getCategory(categoryId) {
            this.$http.get(`/post-categories/${categoryId}`)
            .then(resp => {
                this.category = resp.data.data;
            })
            .catch(error => console.error(error));
        },
  }
}
</script>
