<template>
    <div v-if="isVisible" class="fixed inset-0 flex flex-col items-center justify-center bg-gray-600 bg-opacity-50">
        <div class="p-4 bg-white rounded-lg overflow-hidden shadow-xl">
            <div class="mt-1 mb-3 text-xl">Cambiar Estado Matricula</div>
            <div class="mt-3 mb-1">Profesor</div>
            <div class="mb-4">
                {{  teacher.user.user_profiles.lastname }}, {{  teacher.user.user_profiles.firstname }}
            </div>
            <select v-model="enrollmentStatusId"
                class="form-select block w-full pl-3 pr-10 py-2 text-base leading-6 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option v-for="status in enrollmentStatuses" :key="status.id" :value="status.id">
                    {{ status.name }}
                </option>
            </select>
            <div class="flex mx-4 justify-between">
                <button
                    @click="saveEnrollmentStatus()"
                    class="my-2 px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                    Guardar
                </button>
                <button
                    @click="close"
                    class="my-2 px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalEnrollmentStatusEdit',
    data () {
        return  {
            enrollmentStatusId: null
        }
    },
    watch: {
        isVisible(newValue) {
            if(newValue){
                this.enrollmentStatusId = this.teacher.enrollment_status_id
            }
        }
    },
    props:{
        isVisible: {
            type: Boolean,
            required: true
        },
        enrollmentStatuses: {
            type: Array,
            required: true
        },
        teacher: {
            type: Object,
            required: true
        },
    },
    created(){
        console.log('holaaaaaa1122')
    },
    methods:{
            confirmAction(){
                this.$emit('confirm')
            },
            close(){
                this.$emit('close')
            },
            saveEnrollmentStatus() {
                this.$emit('save',this.enrollmentStatusId)
            }
    }

}
</script>
