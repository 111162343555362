<template>
    <div class="flex flex-col">
      <div class="w-full flex flex-row   my-3 ">
        <div class="flex flex-row justify-between">
          <!-- <div class="mx-4 lg:mx-0"> -->
            <!-- <span  class="absolute inset-y-0 left-0 flex items-center pl-3">
            </span> -->

            <input
            class="w-32 pl-3 pr-4 text-indigo-600 border-gray-200 rounded-md sm:w-64 focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
            type="text"
            v-model="search.name"
            placeholder="Nombre o Apellido"
            >
            <input
            class="w-32 ml-3 pl-2 pr-4 text-indigo-600 border-gray-200 rounded-md sm:w-64 focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
            type="text"
            max="8"
            v-model="search.dni"
            placeholder="DNI o CUIL"
            >
            <select v-model="search.enrollmentStatusId"
                class="form-select block w-full pl-3 pr-10 py-2 ml-3 mr-1 px-2 text-base leading-6 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option v-for="status in enrollmentStatuses" :key="status.id" :value="status.id">
                    {{ status.name }}
                </option>
            </select>
            <button @click="findTeacher"
              class="flex flex-row mx-2 px-3 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
              <svg class="pt-1 mr-1 w-5 h-5 text-gray-500" viewBox="0 0 24 24" fill="none">
                <path
                d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                />
                </svg>
              Buscar
            </button>
        </div>
        <div class="w-full flex justify-end">
            <button @click="goTo('createTeacher')" class="px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                Agregar Profesor
            </button>
        </div>
      </div>
      <div class="text-start text-gray-500 text-md px-1 my-1">
        {{  textFilter }}
      </div>

      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Matricula
                </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Apellido y Nombre
                </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  DNI
                </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Celular
                </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Estado
                </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Fecha Present.</th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Fecha Mat.</th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 ">
                  Modificado</th>

              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(p, index) in enrollments" :key="index" class="hover:bg-gray-200">
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ p.enrollment }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="ml-2">
                      <div @click="goTo('editTeacher', p.id)" class="text-sm font-medium leading-5 text-gray-900 cursor-pointer">
                        {{ p.user.user_profiles.lastname }}, {{ p.user.user_profiles.firstname }}
                      </div>
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="text-sm font-medium leading-5 text-gray-900">
                    {{ p.user.user_profiles.dni }}
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="text-sm font-medium leading-5 text-gray-900">
                    {{ p.user.user_profiles.mobile_phone }}
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ p.enrollment_status.name }}
                        <i class="ml-2 cursor-pointer fas fa-edit" @click="OpenMOdalToEditStatus(p)"></i>
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-2 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        <span v-if="p.submission_date">{{ formatDate(p.submission_date )}}</span>
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-2 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ formatDate(p.enrollment_date )}}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ formatDate(p.updated_at) }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <ModalEditEnrollmentStatus
        :isVisible="isvisibleModal"
        :teacher="teacher"
        :enrollmentStatuses="enrollmentStatuses"
        @save="saveEnrollmentStatus"
        @close="closeModal"
        />
    </div>

</template>

<script>
  import ModalEditEnrollmentStatus from './ModalEditEnrollmentStatus.vue'
  export default {
  name:'Teacher',
    data(){
      return{
        textFilter: 'Ultimos 10 Profesores registrados',
        enrollmentStatusId: null,
        enrollmentStatuses: [],
        enrollments:[],
        search:{ name:''},
        isvisibleModal: false,
        teacher: null,
      }
    },
    components:{
      ModalEditEnrollmentStatus
    },
    created(){
          this.getEnrollments()
          this.getEnrollmentsStatuses()
        },
    methods:{
      closeModal(){
        this.isvisibleModal = false
      },
      saveEnrollmentStatus(enrollment_status_id){
        this.$http.put(`/enrollments/${this.teacher.id}`, {enrollment_status_id: enrollment_status_id})
        .then(res => {
          this.isvisibleModal = false
          this.enrollments.find(e => e.id === this.teacher.id).enrollment_status_id = enrollment_status_id
          this.enrollments.find(e => e.id === this.teacher.id).enrollment_status = this.enrollmentStatuses.find(e => e.id === enrollment_status_id)
        })
      },
      OpenMOdalToEditStatus(p){
        this.teacher = p
        this.isvisibleModal = true
      },
      findTeacher(){
        let params = '';

        if(this.search.name){
          params += `?name=${this.search.name}`
        }

        if(this.search.dni){
          (params.length > 0) ? params += "&" : params += "?"
          params += `dni=${this.search.dni}`
        }

        if(this.search.enrollmentStatusId){
          (params.length > 0) ? params += "&" : params += "?"
          params += `enrollment_status_id=${this.search.enrollmentStatusId}`
        }

        if(params.length > 0){
          params += '&page=all'
        }

        this.getEnrollments(params)

      },
      goTo(item, id = null){
          switch (item) {
            case 'createTeacher':
              this.$router.push(`/teachers/create`)
              break;
            case 'editTeacher':
              this.$router.push(`/teachers/${id}`)
              break;
            default:
              break;
          }

      },
      getEnrollmentsStatuses(){
        this.$http.get('/enrollment_statuses')
        .then( resp => {
            this.enrollmentStatuses= resp.data.data
          })
          .catch(error => {
            console.error('Error al realizar la solicitud :', error);
          });
      },
      getEnrollments(params = ''){
          this.$http.get('/enrollments'+ params)
          .then( resp => {
                this.enrollments = (params) ? resp.data.data : resp.data.data.data

                this.textFilter = (params) ? 'Total profesores encontrados ' + this.enrollments.length : 'Ultimos 10 Profesores registrados'
          })
          .catch(error => {
          console.error('Error al realizar la solicitud :', error);
        });
        },

        formatDate(StringDate){
              const date  =  new Date (StringDate)
              return date.toLocaleDateString()
        }
    }

  }
</script>