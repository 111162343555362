<template>
    <div v-if="actionCreate || (!actionCreate && category)" class="bg-gray-100 p-6 rounded-md shadow-md">
    <div class="flex flex-col space-y-4">

        <!-- Titulo -->
        <div>
            <span class="text-xl">
                {{ actionCreate ? 'Crear Categoria' : 'Editar Categoria' }}
            </span>
        </div>

        <!-- input name -->
        <div>
            <label class="text-gray-700" for="title">Nombre</label>
            <input
                class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                type="text" id="title" name="title"
                v-model="formCategory.name">
        </div>

        <!-- input description -->
        <div>
            <label class="text-gray-700" for="title">Descripcion</label>
            <input
                class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                type="text" id="title" name="title"
                v-model="formCategory.description">
        </div>

        <!-- Buttons    -->
        <div class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <button @click="showConfirmAction" class="px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                Guardar
            </button>
            <button class="px-4 py-2 text-gray-200 bg-gray-500 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                Cancelar
            </button>
        </div>

        <!-- ComponentsConfirAction : Modal de Confirmacion -->
        <ConfirmAction
        :isVisible="isvisibleModal"
        @confirm="savePost"
        @cancel="closeConfirmAction"
        title='¿Quieres guardar la categoría?'
        action='Guardar'
        description="Al presionar aceptar,los cambios se guardaran permanentemente"
        />

    </div>
</div>
</template>

<script>
import ConfirmAction from '../commons/ConfirmAction.vue'

export default {
    name: 'CreateEditCategory',
    components: { ConfirmAction },

    data() {
        return {
            categoryId: null,
            category: null,
            actionCreate: false,
            isvisibleModal: false,
            formCategory: {
                name: "",
                description: "",
            }
        }
    },

    created(){
        this.categoryId = this.$route.params.id;

        if(this.categoryId){
            this.getCategory(this.categoryId)
            this.actionCreate = false
        } else {
            this.actionCreate = true
        }
    },

    methods: {
        getCategory(categoryId) {
            this.$http.get(`/post-categories/${categoryId}`)
            .then(resp => {
                console.log(resp.data.data)
                this.category = resp.data.data;
                this.formCategory.name = this.category.name
                this.formCategory.description = this.category.description
            })
            .catch(error => console.error(error));
        },

        //ActionModal
        showConfirmAction(){
            this.isvisibleModal = true
        },

        closeConfirmAction(){
            this.isvisibleModal =false
        },


        savePost(){
            //Preparado de ta de formulario  para enviar a endpoint

            if(this.actionCreate) {
                this.$http.post(`/post-categories`, this.formCategory)
                .then( resp => {
                    this.isvisibleModal = false;
                    this.$router.push(`/post-categories`)
                })
                .catch(error => {
                    console.error('Error al guardar el categoria', error);
                });
            } else {
                this.$http.put(`/post-categories/${this.categoryId}`, this.formCategory)
                .then( resp => {
                    this.isvisibleModal = false;
                    this.$router.push(`/post-categories`)
                })
                .catch(error => {
                    console.error('Error al guardar el post', error);
                });
            }
        }
    }
}
</script>

