import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TeacherView from '../views/TeacherView.vue'
import PostView from '../views/PostView.vue'
import UserView from '../views/UserView.vue'
import ConfigurationView from '../views/ConfigurationView.vue'
import CreateEditUser from '../components/user/CreateEditUser.vue'
import CreateEditPost from '../components/post/CreateEditPost.vue'
import UploadImage from '../components/example/UploadImage.vue'
import CategoryPostView from '../views/CategoryPostView.vue'
import CreateEditCategory from '../components/post/CreateEditCategory.vue'
import CreateEditTeacher from '../components/teacher/CreateEditTeacher.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Inicio - Mi Aplicación Vue'
    }
  },
  {
    path: '/teachers',
    name: 'teachers',
    component: TeacherView
  },
  {
    path: '/posts',
    name: 'posts',
    component: PostView
  },
  {
    path: '/users',
    name: 'users',
    component: UserView
  },
  {
    path: '/users/:id',
    component: CreateEditUser
  },
  {
    path: '/posts/:id',
    name: 'editPost',
    component: CreateEditPost
  },
  {
    path: '/posts/create',
    name: 'createPost',
    component: CreateEditPost
  },
  {
    path: '/configurations',
    name: 'configurations',
    component: ConfigurationView
  },
  {
    path: '/post-categories',
    name: 'categories',
    component: CategoryPostView
  },
  {
    path: '/post-categories/:id',
    name: 'editCategory',
    component: CreateEditCategory
  },
  {
    path: '/post-categories/create',
    name: 'createCategory',
    component: CreateEditCategory
  },
  {
    path: '/teachers/create',
    name: 'createTeacher',
    component: CreateEditTeacher
  },
  {
    path: '/teachers/:id',
    name: 'editTeacher',
    component: CreateEditTeacher
  },
  {
    path: '/images',
    name: 'images',
    component: UploadImage
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
