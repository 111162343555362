<template>
    <div class="flex flex-col">
      <div class="w-full flex flex-row   my-3 justify-between">
        <div class="flex flex-wrap items-center justify-between mx-4 lg:mx-0">
          <div class="relative flex items-center w-full sm:w-64 mb-3 sm:mb-0">
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <i class="fas fa-search"></i>
            </span>
            <input
              class="w-full pl-10 pr-4 text-indigo-600 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              type="text"
              placeholder="Buscar..."
              v-model="searchpost"
            >
          </div>
          <div class="ml-3 mb-3 sm:mb-0">
            <select
            v-model="selectcategory"
            @change="select"
            class="w-full sm:w-auto px-4">
              <option value="">Todas las categorías</option>
              <option v-for="category in categories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="ml-3 mb-3 sm:mb-0">
            <select
            v-model="selectstatus"
            @change="select"
            class="w-full sm:w-auto px-4">
              <option value="">Estados</option>
              <option v-for="status in statuses" :key="status.id" :value="status.id">
                {{ status.name }}
              </option>
            </select>
          </div>
          <button
            @click="searchPost"
            class="ml-3 px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
          >
            Buscar
          </button>
        </div>
        <div>
            <button @click="goTo('createPost')" class="px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                Crear Publicación
            </button>
        </div>
      </div>
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Id
                </th>

                <th class="px-6 py-3 text-xs  font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Titulo
                </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Id Usuario
                </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Categoria </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Estado </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Fecha </th>
                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 ">
                  Modificado</th>

              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(p, index) in posts" :key="index" class="hover:bg-gray-200">
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ p.id }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="ml-2">
                    <div @click="goTo('editPost', p.id)" class="text-sm font-medium leading-5 text-gray-900 cursor-pointer">
                      {{ p.title }}
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ p.user.user_profiles.firstname }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-2 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ p.post_category.name}}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-2 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ p.post_status.name }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-2 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ formatDate(p.created_at )}}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ formatDate(p.updated_at) }}
                      </div>
                    </div>
                  </div>
                </td>


              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</template>
<script>
  export default {
  name:'Post',
    data(){
      return{
        selectstatus: '',
        selectcategory:''
        ,
        searchpost:" ",
        posts:[],
        categories:[],
        statuses:[]

      }
    },
    created(){
          this.getPosts()
          // console.log('post....')
          this.getCategories()
          this.getStatuses()
        },
    // watch: {
    // searchpost(value) {
    //   if (!value.trim()) {
    //     this.getPosts();
    //   }
    // }},
    methods:{
      goTo(item, id = null){
          switch (item) {
            case 'createPost':
              this.$router.push(`/posts/create`)
              break;
            case 'editPost':
              this.$router.push(`/posts/${id}`)
              break;
            default:
              break;
          }

        },
      getPosts(){
          this.$http.get('/posts')
          .then( resp => {
              // console.log(resp.data.data.data);
              this.posts= resp.data.data.data

            })
          .catch(error => {
          console.error('Error al realizar la solicitud :', error);
        });
        },

        formatDate(StringDate){
              const date  =  new Date (StringDate)
              return date.toLocaleDateString()
        },
        getCategories(){
          this.$http.get('/post-categories')
          .then( resp => {
              // console.log(resp.data.data);
              this.categories= resp.data.data

            })
          .catch(error => {
          console.error('Error al realizar la solicitud :', error);
        });
        },
        getStatuses(){
          this.$http.get('/post-statuses')
          .then( resp => {
              // console.log(resp.data.data);
              this.statuses= resp.data.data

            })
          .catch(error => {
          console.error('Error al realizar la solicitud :', error);
        });
        },
        searchPost() {
            let params = {};
            if (this.searchpost.trim()) {
              params.title = this.searchpost.trim().toLowerCase();
            }
            if (this.selectcategory) {
              params.post_category_id = this.selectcategory;
            }
            if (this.selectstatus) {
              params.post_status_id = this.selectstatus;
            }
          this.$http.get('/posts?', { params })
          .then(resp => {
            console.log(resp.data.data.data);
            this.posts = resp.data.data.data;
            this.searchpost=""
            // this.selectcategory=""
            // this.selectstatus=""

          })
          .catch(error => {
          console.error('Error al buscar las publicaciones:', error);
          });
},








  }
  }
</script>

<style>

</style>