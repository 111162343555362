<template>
  <div class="flex h-screen bg-gray-200 font-roboto">
    <Sidebar :isOpen="isOpen" @update:isOpen="handleMenuUpdate"/>

    <div class="flex-1 flex flex-col overflow-hidden">
      <Header :isOpen="isOpen" @toggleMenu="toggleMenu" />

      <main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
        <div class="w-full mx-auto px-5 py-4">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Sidebar from './Siderbar.vue'
import Header from './Header.vue'

export default defineComponent({
    components: {
        Sidebar,
        Header,
    },
    data(){
      return {
        isOpen: false
      }
    },
    methods:{
      toggleMenu() {
        this.isOpen = !this.isOpen;
      },
      handleMenuUpdate(isOpen) {
        
        this.isOpen = isOpen;
      },
    }
});
</script>
